import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentCheckbox.figmaUrl.android} codeUrl={checklists.componentCheckbox.codeUrl.android} checklists={checklists.componentCheckbox.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Checkboxes allow users to select one or more items from a set. Checkboxes can turn an option on or off.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/checkbox-android-1.webp",
            "align": "center",
            "resize-mode": "contain"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The following is how to implement Checkbox component in Legion :`}</p>
    <h3>{`Single`}</h3>
    <p>{`The basic checkbox component is used for individual checkboxes or basic checkbox needs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var checkedState by remember { mutableStateOf(false) }

LgnCheckbox(
    checked = checkedState,
    onCheckedChange = { 
        checkedState = it
    },
    label = "Checkbox Label",
    enabled = true,
    colors = LgnCheckboxDefaults.colors(),
    size = LgnCheckboxSize.Medium,
)
`}</code></pre>
    <h3>{`Single Checkbox Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`checked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether or not this checkbox is checked.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCheckedChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called when this checkbox is clicked. If `}<inlineCode parentName="td">{`null`}</inlineCode>{`, then this checkbox will not be interactable, unless something else handles its input events and updates its state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`((Boolean) -> Unit)?`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Modifier to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text label for this checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String?`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Controls the enabled state of this checkbox. When `}<inlineCode parentName="td">{`false`}</inlineCode>{`, this component will not respond to user input, and it will appear visually disabled and disabled to accessibility services.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`colors`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnCheckboxColors that will be used to resolve the colors used for this checkbox in different states. See LgnCheckboxDefaults.colors.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnCheckboxColors`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`size`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size to be applied to the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnCheckboxSize`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`interactionSource`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The source of the interactions for the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MutableInteractionSource`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Group`}</h3>
    <p>{`Components for grouping checkboxes, to make it easier if you need checkbox options on your form.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var checkedGroup by remember { 
    mutableStateOf(mutableListOf<Int>()) 
}

val optionCheckboxGroup = listOf(
    LgnCheckboxItemData(
        id = 1,
        label = "Checkbox 1"
    ),
    LgnCheckboxItemData(
        id = 2,
        label = "Checkbox 2"
    ),
    LgnCheckboxItemData(
        id = 3,
        label = "Checkbox 3"
    )
)


LgnCheckboxGroup(
    data = optionCheckboxGroup,
    checkedIds = checkedGroup,
    onCheckedItem = {
        checkedGroup = if (checkedGroup.contains(it.id)) {
            (checkedGroup - it.id).toMutableList()
        } else {
            (checkedGroup + it.id).toMutableList()
        }
    }
)
`}</code></pre>
    <h3>{`Checkbox Group Attributes`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of `}<inlineCode parentName="td">{`LgnCheckboxItem`}</inlineCode>{` data to provide options in `}<inlineCode parentName="td">{`LgnCheckboxGroup`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`List<T>`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`checkedIds`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of `}<inlineCode parentName="td">{`LgnCheckboxItem.id`}</inlineCode>{` to provide checked items in `}<inlineCode parentName="td">{`LgnCheckboxGroup`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`List<Int>`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onCheckedItem`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called when this checkbox item is clicked. If `}<inlineCode parentName="td">{`null`}</inlineCode>{`, then this checkbox will not be interactable, unless something else handles its input events and updates its state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`((item: T) -> Unit)?`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`modifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Modifier to be applied to the checkbox group.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Modifier`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`spacedBetweenItem`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To define spacing between checkbox items.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Dp`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      